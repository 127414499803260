import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>

            <div className="container-xxl text-light link-white footer my-2 mb-0 py-5 bg-dark">
                <div className="row p-4 g-6">
                    <ul className="col-lg-3 col-md-6 list-unstyled  ">
                        <li> <h3 className="text-light mb-2">Quick Links</h3></li>
                        <li>  <Link onClick={scrollToTop} className="footer-link" to="/"  >Home</Link></li>
                        <li> <Link onClick={scrollToTop} className="footer-link" to="/About"  >About Us</Link></li>
                        <li>   <Link onClick={scrollToTop} className="footer-link" to="/Services"  >Services</Link></li>
                        <li>  <Link onClick={scrollToTop} className="footer-link" to="/Solutions"  >Solutions</Link></li>
                        <li>  <Link onClick={scrollToTop} className="footer-link" to="/Contactus" >Contact Us</Link></li>
                        <li>  <Link onClick={scrollToTop} className="footer-link" to="/Sitemap" >Site Map</Link></li>

                    </ul>
                    {/* <ul className="col-lg-3 col-md-6 list-unstyled ">
                <li><h3 className="text-light mb-2">Solutions</h3></li>
                    <li> <Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link"to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Popular Links</Link></li>
                </ul> */}
                    {/* <ul className="col-lg-3 col-md-6 list-unstyled ">
                <li><h3 className="text-light mb-2">Services</h3></li>
                    <li> <Link  onClick={scrollToTop} className="footer-link" to="#">Mailing</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link"to="#">Welding</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Assembling</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Metrology</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Methodology</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Nuclear Services</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Instrumental Action</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="#">Hyduculus</Link></li>
                    <li><Link  onClick={scrollToTop} className="footer-link" to="/Software-Development">Software Develpoment</Link></li>

                </ul> */}
                    {/* <hr /> */}
                    <ul className="col-lg-3 col-md-6 list-unstyled  ">
                        <li> <h3 className="text-light mb-2">Contact Us</h3></li>
                        <li> <Link className="footer-link" to="#" target="_blank" ><i className="fa fa-phone me-3"></i>+44 20 8004 4852</Link></li>
                        <li>  <Link href="mailto:info@proenergy.ltd" className="footer-link" target="_blank" ><i className="fa fa-envelope me-3"></i>info@proenergy.ltd</Link></li>
                        <li> <Link className="footer-link" target="_blank" href="https://proenergy.ltd"><i className="fa fa-chrome me-3"></i>proenergy.ltd</Link></li>
                        <div className="d-flex pt-1">
                            <Link className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="bi bi-whatsapp"></i></Link>
                            <Link className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="fab fa-facebook-f"></i></Link>
                            <Link className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="fab fa-linkedin-in"></i></Link>
                            <Link className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="fab fa-instagram"></i></Link>
                            <Link className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="fab fa-twitter"></i></Link>
                            <Link className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="fab fa-youtube"></i></Link>

                        </div>

                    </ul>
                    <div class="col-lg-3 col-md-6">
                        <h3 class="text-light mb-2">Address</h3>
                        <div style={{ display: "flex" }} >
                            <i class="fa fa-map-marker" style={{ font: "large" }}></i>
                            <i style={{ font: "large" }} >2nd Floor, 134-South Street, Romford. RM1 1TE</i>
                        </div>
                    </div>




                </div></div>
            <div id="hero1" class="container-xxl copyright text-light py-4  " >

                <div class="row">
                    <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        Copyright © 2024 | Pro Energy Pvt Ltd.
                    </div>
                    <div class="col-md-6 text-center text-md-end">
                        Designed By  <Link className="text-white" target="_blank" to="https://www.facebook.com/aimencoders">aimEncoders</Link>
                    </div>
                </div>

            </div>
        </>
    );
}
export default Footer;