import React from 'react';
import { Routes, Route} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "./App.css";
import Home from './pages/Home.jsx';
import Services from './pages/Services';
// import Careers from './pages/Careers.jsx';
// import Sitemap from './pages/Sitemap';
// import Teams from './pages/Team/Teams.jsx';
import About from './pages/About';
import Blogs from './pages/Blogs.jsx'
import Portfolio from './pages/Portfolio.jsx'
import Gallery from './pages/Gallery.jsx'
import Contactus from './pages/Contactus';
// import Solutions from './pages/Solutions';
import Navbar2 from './Header_Footer/Navbar/Navbar.jsx';
import Footer from './Header_Footer/Footer/Footer';  
// import Traning from './pages/Traning.jsx';
import Whatsapp from './Header_Footer/Whatsapp/Whatsapp.jsx';
import Topnav from './Header_Footer/Toper/Topnav.jsx';
import Socialicon from "./MainPagePart/Socialicons/Social.jsx"
import Software from "./pages/Softwaredevelopment/Software.jsx"

const App=() =>{
  return(
  <>
    <Navbar2/>
   <Routes> 
        <Route path="/" element={<Home />}/>
        <Route path="/Services" element={<Services />}/>
        <Route path="/About" element={<About />}/>
        <Route path="/Contactus" element={<Contactus />}/>
        {/* <Route path="/Solutions" element={<Solutions />}/> */}
        {/* <Route path="/Teams" element={<Teams/>}/> */}
        {/* <Route path="/Traning" element={<Traning />}/> */}
        {/* <Route path="/Sitemap" element={<Sitemap />}/>  */}
        {/* <Route path="/Careers" element={<Careers />}/>  */}
        <Route path="/Gallery" element={<Gallery />}/> 
        <Route path="/Blogs" element={<Blogs />}/> 
        <Route path="/Portfolio" element={<Portfolio />}/> 
        <Route path="/Software-Development" element={<Software />}/> 
        <Route path="*" element={<Home/>} /> 
      </Routes>
      <Socialicon/>
      <Whatsapp/>
      <Topnav/>
      <Footer/>
      </>
  );
}
export default App;