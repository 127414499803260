import React from "react";
import "../style/solutions.css";
import img1 from "../style/img/service-1.jpg";
import img2 from "../style/img/service-2.jpg";
import img3 from "../style/img/service-3.jpg";
import img4 from "../style/img/service-4.jpg";
import img5 from "../style/img/service-5.jpg";
import img6 from "../style/img/service-6.jpg";
import img7 from "../style/img/service-7.jpg";
import img8 from "../style/img/service-8.jpg";
import img9 from "../style/img/service-9.jpg";
import img10 from "../style/img/service-10.jpg";
import img11 from "../style/img/service-11.jpg";
import img12 from "../style/img/service-12.jpg";
const Solutions = () => {

    return (
        <>
            <div class="container-xxl mt-4">
                <div class="row gy-5 py-5 gx-4">
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="100">
                        <div class="service-item">
                            <img class="img-fluid" src={img1} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img1} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Boiler Replacement</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 " data-aos="zoom-in" data-aos-delay="150">
                        <div class="service-item">
                            <img class="img-fluid" src={img2} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img2} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Solar Panels</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img3} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img3} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Loft Insulations</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img4} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img4} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">First Time Centeral Heating System</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img5} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img5} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Room in Roof Insulation</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img6} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img6} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Under Floor Insulation</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img7} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img7} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Internal Wall Insulation</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img8} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img8} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">External Wall Insulation</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img9} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img9} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Heating Controls</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img10} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img10} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Damp Treatment</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img11} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img11} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Home Energy Reports</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3" data-aos="zoom-in" data-aos-delay="200">
                        <div class="service-item">
                            <img class="img-fluid" src={img12} alt="" />
                            <div class="service-img">
                                <img class="img-fluid" src={img12} alt="" />
                            </div>
                            <div class="service-detail">
                                <div class="service-title">
                                    <hr class="w-25" />
                                    <h3 class="mb-0">Retrofit Assessments</h3>
                                    <hr class="w-25" />
                                </div>
                                <div class="service-text">
                                    <p class="text-white mb-0"> </p>
                                </div>
                            </div>
                            <a class="btn btn-light" href="">Read More</a>
                        </div>
                    </div>
                </div>
            </div>


        </>

    );

}
export default Solutions;