import React from "react";
// import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const Callaction = () =>{
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
return(
<>
    <section id="cta" className="cta bddrad">
      <div className="row">
<div className="text-center">
          <h3>Call To Action</h3>
          <p> Under the Energy Company Obligation (ECO) scheme millions of people across the UK are entitled to FREE Heating and Insulation measures. You can apply if you own your home or if you or anyone living with you is in receipt of benefits.</p>
          <Link className="cta-btn" onClick={scrollToTop} to="/Contactus">Apply in 30 Seconds</Link>
        </div>

</div>
    </section>

</>


);
}
export default Callaction;