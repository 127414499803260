import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Toper from "../Toper/Toper.jsx";
import { NavDropdown } from 'react-bootstrap';
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNavLinkClick = () => {
    setNavbarOpen(false);
  };
  const navfun = () => {
    handleNavLinkClick();
    scrollToTop();

  }

  return (
    <>

      <Toper />
      <nav className={`navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 shadow-sm ${navbarOpen ? 'open' : ''}`} aria-label="Fourth navbar example">
        <div className="container-xxl">
          <NavLink className="navbar-brand" onClick={navfun} to="/">
            <img className='logo11' src={process.env.PUBLIC_URL + '/Images/PELogo/PE_Logo.png'} alt="Pro Energy Ltd is a specialist Heat and insulation solution provider accredited under ECO Scheme. The company provides expert solutions including free boiler replacement, electric storage heating and home insulation.Under the Energy Company Obligation (ECO) scheme millions of people across the UK are entitled to FREE Heating and Insulation measures." />
          </NavLink>
          <button className="navbar-toggler" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`} id="navbarsExample04">
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <NavLink className="nav-link" aria-current="page" onClick={navfun} to="/">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={navfun} to="/About">About</NavLink>
              </li>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Boiler Replacement</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Home Insulation</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Electric Storage Heaters</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Energy Proficiency Report</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Retrofit Assessment & Coordination</NavDropdown.Item>
                {/*  <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Nuclear Services</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Instrumental Action</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Hyduculus</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Services" onClick={navfun}>Software Develpoment</NavDropdown.Item>
          */}
              </NavDropdown>

              {/* <NavDropdown title="Solutions" id="basic-nav-dropdown">
            <NavDropdown.Item as={NavLink} to="/Solutions" onClick={navfun}>Solutions</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Web-development" onClick={navfun}>Solutions1</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Mobile-Application-Development" onClick={navfun}>Solutions2</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Project-Consultancy" onClick={navfun}>Solutions3</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Domain-and-hosting" onClick={navfun}>Solutions4</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Digital_Marketing" onClick={navfun}>Solutions5</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Hardware-and-Networking" onClick={navfun}>Solutions6</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Shoots-and-TV-Commercials" onClick={navfun}>Solutions7</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Cctv-Cameras" onClick={navfun}>Solutions8</NavDropdown.Item>

          </NavDropdown> */}
              {/* <NavDropdown title="Training" id="basic-nav-dropdown">
            <NavDropdown.Item as={NavLink} to="/Traning" onClick={navfun}>Training</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Web-development" onClick={navfun}>Training1</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Mobile-Application-Development" onClick={navfun}>Training2</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Project-Consultancy" onClick={navfun}>Training3</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Domain-and-hosting" onClick={navfun}>Training4</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Digital_Marketing" onClick={navfun}>Training5</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Hardware-and-Networking" onClick={navfun}>Training6</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Shoots-and-TV-Commercials" onClick={navfun}>Training7</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/Cctv-Cameras" onClick={navfun}>Training8</NavDropdown.Item>

          </NavDropdown> */}



              {/* <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/Portfolio">Portfolio</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/Teams">Team</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/Careers">Careers</NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/Blogs">Blog</NavLink>
          </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" onClick={navfun} to="/Gallery">Gallery</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" onClick={navfun} to="/Contactus">Contact Us</NavLink>
              </li>
              {/* <li className="nav-item">
            <NavLink className="nav-link"onClick={navfun} to="/Sitemap"><i class="bi bi-diagram-3-fill"></i></NavLink>
          </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>




  );

}
export default Navbar;