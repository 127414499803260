import React from "react";
import { Link } from "react-router-dom";
import "./CSS/Parts.css";
const Services1 = () => {
  return (
    <>
      <div id="services" className="services">
        <div className="container-xxl" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="/">Boiler Replacement</Link></h4>
                <p>Our expert team ensures seamless boiler replacement, providing you with a more energy-efficient and reliable heating system to keep your home comfortable throughout the year.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="">Home Insulation</Link></h4>
                <p>Improve your home's energy efficiency with our premium insulation services. We ensure that your home stays warm in winter and cool in summer, reducing your energy bills..</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="">Upgrade Energy efficiency</Link></h4>
                <p>We help you upgrade your home's energy efficiency with advanced solutions, including energy-efficient appliances, smart thermostats, and more, saving you money and reducing your carbon footprint</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="">Centeral Heating System</Link></h4>
                <p>Our comprehensive central heating system installation and maintenance services ensure your home stays warm and cozy with efficient and reliable heating solutions</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="">Energy Proficiency Report</Link></h4>
                <p>Our detailed energy proficiency reports provide you with an in-depth analysis of your home's energy consumption, helping you identify areas for improvement and maximize efficiency</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="">Retrofit Assessments & Coordinations</Link></h4>
                <p>We offer comprehensive retrofit assessment and coordination services, guiding you through the process of upgrading your property to meet modern energy efficiency standards, ensuring a smooth and successful project.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="">Smart home Integeration</Link></h4>
                <p>Transform your living space with smart home integration. We install and configure smart devices that enhance your home's comfort, security, and energy efficiency, all controlled from your smartphone.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
                <div className="icon"><i className="bi bi-gear"></i></div>
                <h4><Link className="a1a1" to="">Solar Panel Installation</Link></h4>
                <p>Harness the power of the sun with our solar panel installation services. We help you reduce energy costs and contribute to a sustainable future by switching to clean, renewable energy.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}
export default Services1;