import React from "react";
import About1 from "../MainPagePart/About1";
const About = () =>{
return(
<>
<div className="breadcrumbs d-flex align-items-center headerbd">
      <div className="container position-relative d-flex flex-column align-items-center">
      <div className=" text-center text-white">
    <h2>About Us</h2>
    </div>
      </div>
    </div>
    
<div class="container-xxl py-3 my-4">
                <div class="row p-4 g-5">
                    <About1/>
</div></div>
<div className="container-xxl">
<hr className="row"/>
  <div className="row justify-content-center ">
    <div className=" p-3">
    <h1>Company Profile</h1>
    </div>

    </div>
    </div>
    

</>


);

}
export default About;