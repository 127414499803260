import React, { useEffect } from "react";
import img3 from "./CSS/PeAbout.jpeg";
import img4 from "./CSS/PeAbout1.jpeg";
import 'aos/dist/aos.css';
import AOS from 'aos';

const About = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    const myStyle = {
        marginBottom: '25%',
    };
    const myStyle2 = {
        position: 'absolute',
        top: '25%',
        left: '25%',
    };
    return (
        <>
            <div class="container-xxl py-0 my-0">
                <div class="row g-5">
                    <div class="col-lg-5 col-md-6 col-sm-12">
                        <div class="h-100 position-relative" >
                            <img src={img3} class="img-fluid w-75 rounded" alt="" style={myStyle} data-aos="zoom-in" data-aos-delay="100" />
                            <div class="position-absolute w-75" style={myStyle2}>
                                <img src={img4} class="img-fluid w-100 rounded" alt="" data-aos="zoom-in" data-aos-delay="100" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-6 col-sm-12 " data-aos="zoom-in" data-aos-delay="500">
                        {/* <h5 class="text-primary">About Us</h5> */}
                        <h1 class="mb-4">ProEnergy</h1>
                        <p>"Pro Energy Ltd, accredited under the ECO Scheme, specializes in energy-efficient solutions such as central heating system installations and retrofitting. With a diverse team experienced in residential and commercial properties, we've evolved from door-to-door sales to managing extensive portfolios.

Committed to affordability and effectiveness, we've become exclusive contractors for government-supported schemes, offering retrofit assessments and boiler installations. Our expanding portfolio includes partnerships with estate agents, ensuring superior services for landlords and homeowners seeking energy-efficient solutions."</p>
                    </div>
                </div>
            </div>
        </>


    );

}
export default About;