import React from "react";
import { Link } from "react-router-dom";
import "./Tober.css"
const Toper=()=>{
    return(
<>
<div id="hero1" className="container-fluid py-2 d-none d-md-flex p-0  ">
            <div className="container">
                <div className="d-flex justify-content-between topbar">
                    <div className="top-info">
                        <small className="me-2 text-light"><Link to="#"><i className="fas fa-map-marker-alt me-1 text-secondary a112"></i></Link>2nd Floor, 134-South Street, Romford.
RM1 1TE</small>
                        <small className="me-2 text-light"><Link to="#"><i className="fas fa-envelope me-1 text-secondary a112"></i></Link>info@proenergy.ltd</small>
                    </div>
                    <div id="note" className="text-secondary text-light d-none d-xl-flex"><small>Pro Energy Ltd is a specialist Heat and insulation solution provider accredited under ECO Scheme.</small></div>
                    <div className="top-link">
                    <Link className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="bi bi-whatsapp"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1"  target="_blank" to="#"><i className="fab fa-linkedin-in"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="fab fa-instagram"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank" to="#"><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-square btn-outline-light me-1" target="_blank"  to="#"><i className="fab fa-youtube"></i></Link>
                    </div>
                </div>
            </div>
        </div>

</>
    );
}
export default Toper;