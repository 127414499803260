import React from "react";
import Services1 from "../MainPagePart/Services1";

const Services = () =>{
return(
<>
<div className="breadcrumbs d-flex align-items-center headerbd">
      <div className="container position-relative d-flex flex-column align-items-center" >
      <div className=" text-center text-white">
        <h2>Services</h2>
      </div></div>
    </div>
    
<div class="container-fluid py-0 my-0">
            <div class="container pt-4">
                <div class="row g-5">
<Services1/>
</div></div></div>
</>

);

}
export default Services;