import React,{useRef} from "react";
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const Contact = () =>{
    var style = {
        height: 100
      }
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const form = useRef();

      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_tad7cpw', 'template_bbjztpi', form.current, 'C8nvhy23IrqH1Q-7a')
          .then((result) => {
              console.log(result.text);
              handleSubmit();
          }, (error) => {
              console.log(error.text);
          });
      };
    
      const navigate = useNavigate();

      const handleSubmit = () => {
        navigate('/');
        alert('Your Query has been sent. We will contact you shortly Thanks!');
        scrollToTop();
      };
return(
<>
<div className="container-xxl mt-5 ">

        <div className="row">

        
  
    <div className="col-lg-6 text-center p-3 mt-5" data-wow-delay="0.5s">
                        <h3 >If You Have Any Query, Please Contact Us</h3>
                        <p className="mb-2 p11">Under the Energy Company Obligation (ECO) scheme millions of people across the UK are entitled to FREE Heating and Insulation measures. You can apply if you own your home or if you or anyone living with you is in receipt of benefits. You could save money on your energy bills too.</p>
</div>
<div className="col-lg-6 p-3  " data-wow-delay="0.5s">
<form ref={form} onSubmit={sendEmail}  id="email">

<div className="ccc">
<div className="w-100 mm">
<label for="fname">First Name</label><br/>
<input className="w-100" type="text" id="user_name" name="user_name" placeholder="Your name.." required/>
</div>
<div className="w-100 mm">
<label for="lname">Last Name</label><br/>
<input className="w-100"  type="text" id="user_lname" name="last_name" placeholder="Your last name.." required/>
</div>
</div>
<div className="ccc">
<div className="w-100 mm">
<label for="fname">Mobile No</label><br/>
<input className="w-100" type="number" id="user_number" name="user_number" placeholder="+1" required/>
</div>
<div  className="w-100 mm">
<label for="lname">Email</label><br/>
<input className="w-100" type="text" id="user_email" name="user_email" placeholder="info@ex.com" required/>

</div>
</div>
<div className="ccc">
<div className="w-100">
<label for="country">Country</label><br/>
<select className="w-100 mm"  id="user_country" name="user_country" required><br/>
  <option value="usa">UK</option>
</select>
</div></div>
<div className="ccc">
  <div className="w-100 mm">
<label for="subject">Message</label><br/>
<textarea className="w-100" id="message" name="message" placeholder="Write something.." style={style} required></textarea><br/>
<div className="text-center">
<input type="submit"  value="Submit"/>
</div></div>
</div>
</form>
</div>

{/* google map */}
<iframe className=" w-100 h-200"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.6602885453267!2d0.18070307640114225!3d51.574460905802404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a4cbc502efb1%3A0x5759eba88c7fe6f0!2sPro%20Energy%20Ltd!5e0!3m2!1sen!2s!4v1718186718310!5m2!1sen!2s"
                            frameborder="0" height={400} title="email" allowfullscreen="" aria-hidden="false"
                            tabindex="0"></iframe>

</div>
</div>
    

</>

); 
}
export default Contact;