import React from "react";
import { Link } from "react-router-dom";
import "./Whatsapp.css"
const Whatsapp = ()=>{
return(
<>  
<Link  class="whats-app" to="https://api.whatsapp.com/send?phone=447402927045" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</Link>
</>

);

}
export default Whatsapp;